.ares.tparrows {
  cursor: pointer;
  min-width: 60px;
  min-height: 60px;
  z-index: 100;
  background: #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.ares.tparrows:before {
  color: #aaa;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 25px;
  line-height: 60px;
  transition: color .3s;
  display: block;
  position: relative;
}

.ares.tparrows.tp-leftarrow:before {
  content: "";
}

.ares.tparrows.tp-rightarrow:before {
  content: "";
}

.ares.tparrows:hover:before {
  color: #000;
}

.ares .tp-title-wrap {
  z-index: 1;
  min-height: 60px;
  -webkit-transition: -webkit-transform .3s;
  transform-origin: 0%;
  background: #fff;
  border-radius: 0 30px 30px 0;
  margin-left: 30px;
  line-height: 60px;
  transition: transform .3s;
  display: inline-block;
  position: absolute;
  top: 0;
  overflow: hidden;
  transform: scaleX(0);
}

.ares.tp-rightarrow .tp-title-wrap {
  -webkit-transform-origin: 100%;
  border-radius: 30px 0 0 30px;
  margin-left: 0;
  margin-right: 30px;
  right: 0;
}

.ares.tparrows:hover .tp-title-wrap {
  transform: scaleX(1)scaleY(1);
}

.ares .tp-arr-titleholder {
  -webkit-transition: -webkit-transform .3s;
  text-transform: uppercase;
  color: #000;
  white-space: nowrap;
  opacity: 0;
  margin-left: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 60px;
  transition: transform .3s;
  position: relative;
  transform: translateX(200px);
}

.ares.tp-rightarrow .tp-arr-titleholder {
  margin-left: 0;
  margin-right: 10px;
  transform: translateX(-200px);
}

.ares.tparrows:hover .tp-arr-titleholder {
  opacity: 1;
  transition-delay: .1s;
  transform: translateX(0);
}

.ares.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.ares .tp-bullet {
  width: 13px;
  height: 13px;
  cursor: pointer;
  box-sizing: content-box;
  background: #e5e5e5;
  border-radius: 50%;
  position: absolute;
}

.ares .tp-bullet:hover, .ares .tp-bullet.selected {
  background: #fff;
}

.ares .tp-bullet-title {
  color: #888;
  visibility: hidden;
  white-space: nowrap;
  background: #ffffffbf;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  transition: transform .3s;
  position: absolute;
  top: -4px;
  right: 27px;
  transform: translateX(-20px);
}

.ares .tp-bullet-title:after {
  width: 0;
  height: 0;
  content: " ";
  border: 10px solid #0000;
  border-left-color: #ffffffbf;
  border-right-width: 0;
  position: absolute;
  top: 0;
  right: -10px;
}

.ares .tp-bullet:hover .tp-bullet-title {
  visibility: visible;
  transform: translateX(0);
}

.ares .tp-bullet.selected:hover .tp-bullet-title {
  background: #fff;
}

.ares .tp-bullet.selected:hover .tp-bullet-title:after {
  border-color: #0000 #0000 #0000 #fff;
}

.ares.tp-bullets:hover .tp-bullet-title {
  visibility: hidden;
}

.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
  visibility: visible;
}

.ares .tp-tab {
  opacity: 1;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  font-family: Roboto, sans-serif;
}

.ares .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  float: left;
  display: inline-block;
  position: relative;
}

.ares .tp-tab-content {
  box-sizing: border-box;
  color: #333;
  width: 100%;
  height: 100%;
  background: none;
  margin-top: -15px;
  padding: 15px 15px 15px 85px;
  display: inline-block;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.ares .tp-tab-date {
  color: #aaa;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.ares .tp-tab-title {
  text-align: left;
  color: #333;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: block;
}

.ares .tp-tab:hover, .ares .tp-tab.selected {
  background: #eee;
}

.custom.tparrows {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 10000;
  background: #00000080;
  display: block;
  position: absolute;
}

.custom.tparrows:hover {
  background: #000;
}

.custom.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 15px;
  line-height: 40px;
  display: block;
}

.custom.tparrows.tp-leftarrow:before {
  content: "";
}

.custom.tparrows.tp-rightarrow:before {
  content: "";
}

.custom.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.custom .tp-bullet {
  width: 12px;
  height: 12px;
  cursor: pointer;
  box-sizing: content-box;
  background: #7d7d7d80;
  position: absolute;
}

.custom .tp-bullet:hover, .custom .tp-bullet.selected {
  background: #7d7d7d;
}

.dione.tparrows {
  height: 100%;
  width: 100px;
  background: none;
  line-height: 100%;
  transition: all .3s;
}

.dione.tparrows:hover {
  background: #00000073;
}

.dione .tp-arr-imgwrapper {
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dione.tp-rightarrow .tp-arr-imgwrapper {
  left: auto;
  right: 0;
}

.dione .tp-arr-imgholder {
  width: 100px;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-position: center;
  background-size: cover;
  transition: all .3s;
  top: 0;
  left: 0;
  transform: translateX(-50px);
}

.dione.tparrows.tp-rightarrow .tp-arr-imgholder {
  left: auto;
  right: 0;
  transform: translateX(50px);
}

.dione.tparrows:before {
  margin-top: -15px;
  margin-left: -22px;
  font-size: 30px;
  line-height: 30px;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.dione.tparrows.tp-rightarrow:before {
  margin-left: 6px;
}

.dione.tparrows:hover:before {
  opacity: 0;
  transform: translateX(-20px);
}

.dione.tparrows.tp-rightarrow:hover:before {
  transform: translateX(20px);
}

.dione.tparrows:hover .tp-arr-imgholder {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.dione .tp-bullet {
  opacity: 1;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: #00000040;
  margin: 0;
  padding: 3px;
  transition: all .3s;
}

.dione .tp-bullet-image {
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  background-position: center;
  background-size: cover;
  display: block;
  position: relative;
  box-shadow: inset 5px 5px 10px #00000040;
}

.dione .tp-bullet-title {
  color: #fff;
  opacity: 0;
  transform-origin: 50% 100%;
  opacity: 0;
  white-space: nowrap;
  background: #000000bf;
  border-radius: 4px;
  padding: 10px 30px;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateZ(.001px)translateX(-50%)translateY(14px);
}

.dione .tp-bullet:hover .tp-bullet-title {
  opacity: 1;
  transform: rotateX(0)translateX(-50%);
}

.dione .tp-bullet.selected, .dione .tp-bullet:hover {
  background: -moz-linear-gradient(top, #fff 0%, #777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fff), color-stop(100%, #777));
  background: -o-linear-gradient(top, #fff 0%, #777 100%);
  background: -ms-linear-gradient(top, #fff 0%, #777 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#777777", GradientType= 0);
  background: linear-gradient(#fff 0%, #777 100%);
}

.dione .tp-bullet-title:after {
  content: " ";
  width: 0;
  height: 0;
  border: 8px solid #0000;
  border-top-color: #000000bf;
  border-bottom-width: 0;
  margin-left: -8px;
  position: absolute;
  bottom: -8px;
  left: 50%;
}

.erinyen.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  z-index: 1000;
  background: #00000080;
  border-radius: 35px;
  display: block;
  position: absolute;
}

.erinyen.tparrows:before {
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 20px;
  line-height: 70px;
  display: block;
  position: relative;
}

.erinyen.tparrows.tp-leftarrow:before {
  content: "";
}

.erinyen.tparrows.tp-rightarrow:before {
  content: "";
}

.erinyen .tp-title-wrap {
  z-index: 1;
  min-height: 70px;
  visibility: hidden;
  opacity: 0;
  background: #00000080;
  border-radius: 35px;
  margin-left: 0;
  line-height: 70px;
  transition: opacity .3s;
  display: inline-block;
  position: absolute;
  top: 0;
  overflow: hidden;
  transform: scale(0);
}

.erinyen.tparrows:hover .tp-title-wrap {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.erinyen.tp-rightarrow .tp-title-wrap {
  -webkit-transform-origin: 100%;
  border-radius: 35px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  padding-right: 20px;
  right: 0;
}

.erinyen.tp-leftarrow .tp-title-wrap {
  padding-left: 20px;
  padding-right: 10px;
}

.erinyen .tp-arr-titleholder {
  letter-spacing: 3px;
  -webkit-transition: -webkit-transform .3s;
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  margin-left: 11px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 70px;
  transition: transform .3s;
  position: relative;
  transform: translateX(200px);
}

.erinyen .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.erinyen .tp-arr-img-over {
  width: 100%;
  height: 100%;
  background: #00000080;
  position: absolute;
  top: 0;
  left: 0;
}

.erinyen.tp-rightarrow .tp-arr-titleholder {
  margin-left: 0;
  margin-right: 11px;
  transform: translateX(-200px);
}

.erinyen.tparrows:hover .tp-arr-titleholder {
  opacity: 1;
  transition-delay: .1s;
  transform: translateX(0);
}

.erinyen.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  background: -o-linear-gradient(top, #555 0%, #222 100%);
  background: -ms-linear-gradient(top, #555 0%, #222 100%);
  filter: progid:dximagetransform. microsoft. gradient(startcolorstr= "#555555", endcolorstr= "#222222", gradienttype= 0);
  box-sizing: content-box;
  background: linear-gradient(#555 0%, #222 100%);
  border-radius: 10px;
  margin-top: -10px;
  margin-left: -15px;
  padding: 10px 15px;
  position: absolute;
  box-shadow: 0 0 2px 1px #2121214d;
}

.erinyen .tp-bullet {
  width: 13px;
  height: 13px;
  cursor: pointer;
  box-sizing: content-box;
  background: #111;
  border-radius: 50%;
  position: absolute;
}

.erinyen .tp-bullet:hover, .erinyen .tp-bullet.selected {
  background: -o-linear-gradient(top, #e5e5e5 0%, #999 100%);
  background: -ms-linear-gradient(top, #e5e5e5 0%, #999 100%);
  filter: progid:dximagetransform. microsoft. gradient(startcolorstr= "#e5e5e5", endcolorstr= "#999999", gradienttype= 0);
  width: 12px;
  height: 12px;
  background: linear-gradient(#e5e5e5 0%, #999 100%);
  border: 1px solid #555;
}

.erinyen .tp-thumb {
  opacity: 1;
}

.erinyen .tp-thumb-over {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #00000040;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.erinyen .tp-thumb-more:before {
  color: #aaa;
  color: #ffffffbf;
  text-align: left;
  z-index: 2;
  z-index: 2;
  content: "";
  font-family: revicons;
  font-size: 12px;
  line-height: 12px;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.erinyen .tp-thumb-title {
  letter-spacing: 1px;
  color: #fff;
  text-align: left;
  z-index: 2;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 35px 20px 20px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.erinyen .tp-thumb.selected .tp-thumb-more:before, .erinyen .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa;
}

.erinyen .tp-thumb.selected .tp-thumb-over, .erinyen .tp-thumb:hover .tp-thumb-over {
  background: #fff;
}

.erinyen .tp-thumb.selected .tp-thumb-title, .erinyen .tp-thumb:hover .tp-thumb-title {
  color: #000;
}

.erinyen .tp-tab-title {
  color: #a8d8ee;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  font-family: "Roboto Slab" margin-bottom:5px;
}

.erinyen .tp-tab-desc {
  color: #fff;
  font-family: Roboto Slab;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.gyges.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  background: -o-linear-gradient(top, #777 0%, #666 100%);
  background: -ms-linear-gradient(top, #777 0%, #666 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#777777", endColorstr= "#666666", GradientType= 0);
  box-sizing: content-box;
  background: linear-gradient(#777 0%, #666 100%);
  border-radius: 10px;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.gyges .tp-bullet {
  width: 12px;
  height: 12px;
  cursor: pointer;
  box-sizing: content-box;
  background: #333;
  border: 3px solid #444;
  border-radius: 50%;
  position: absolute;
}

.gyges .tp-bullet:hover, .gyges .tp-bullet.selected {
  background: -o-linear-gradient(top, #fff 0%, #e1e1e1 100%);
  background: -ms-linear-gradient(top, #fff 0%, #e1e1e1 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#e1e1e1", GradientType= 0);
  background: linear-gradient(#fff 0%, #e1e1e1 100%);
}

.gyges .tp-thumb {
  opacity: 1;
}

.gyges .tp-thumb-img-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #00000040;
  margin: 0;
  padding: 3px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.gyges .tp-thumb-image {
  box-sizing: border-box;
  padding: 3px;
  display: block;
  position: relative;
  box-shadow: inset 5px 5px 10px #00000040;
}

.gyges .tp-thumb-title {
  opacity: 0;
  transform-origin: 50% 100%;
  white-space: nowrap;
  background: #fffc;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 10px 30px;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateZ(.001px)translateX(-50%)translateY(14px);
}

.gyges .tp-thumb:hover .tp-thumb-title {
  opacity: 1;
  transform: rotateX(0)translateX(-50%);
}

.gyges .tp-thumb:hover .tp-thumb-img-wrap, .gyges .tp-thumb.selected .tp-thumb-img-wrap {
  background: -moz-linear-gradient(top, #fff 0%, #777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fff), color-stop(100%, #777));
  background: -o-linear-gradient(top, #fff 0%, #777 100%);
  background: -ms-linear-gradient(top, #fff 0%, #777 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#777777", GradientType= 0);
  background: linear-gradient(#fff 0%, #777 100%);
}

.gyges .tp-thumb-title:after {
  content: " ";
  width: 0;
  height: 0;
  border: 8px solid #0000;
  border-top-color: #fffc;
  border-bottom-width: 0;
  margin-left: -8px;
  position: absolute;
  bottom: -8px;
  left: 50%;
}

.gyges .tp-tab {
  opacity: 1;
  box-sizing: border-box;
  border-bottom: 1px solid #ffffff26;
  padding: 10px;
  font-family: Roboto, sans-serif;
}

.gyges .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  float: left;
  display: inline-block;
  position: relative;
}

.gyges .tp-tab-content {
  box-sizing: border-box;
  color: #333;
  width: 100%;
  height: 100%;
  background: none;
  margin-top: -15px;
  padding: 15px 15px 15px 85px;
  display: inline-block;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.gyges .tp-tab-date {
  color: #ffffff40;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.gyges .tp-tab-title {
  text-align: left;
  color: #fff;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: block;
}

.gyges .tp-tab:hover, .gyges .tp-tab.selected {
  background: #00000080;
}

.hades.tparrows {
  cursor: pointer;
  width: 100px;
  height: 100px;
  z-index: 1000;
  background: #00000026;
  display: block;
  position: absolute;
}

.hades.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 30px;
  line-height: 100px;
  transition: background .3s, color .3s;
  display: block;
}

.hades.tparrows.tp-leftarrow:before {
  content: "";
}

.hades.tparrows.tp-rightarrow:before {
  content: "";
}

.hades.tparrows:hover:before {
  color: #aaa;
  background: #fff;
}

.hades .tp-arr-allwrapper {
  width: 100px;
  height: 100px;
  -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=0)";
  filter: alpha(opacity= 0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  transform-origin: 0%;
  background: #888;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 100%;
  transform: rotateY(-90deg);
}

.hades.tp-rightarrow .tp-arr-allwrapper {
  transform-origin: 100%;
  left: auto;
  right: 100%;
  transform: rotateY(90deg);
}

.hades:hover .tp-arr-allwrapper {
  -ms-filter: "progid:dximagetransform.microsoft.alpha(opacity=100)";
  filter: alpha(opacity= 100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  transform: rotateY(0);
}

.hades .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hades.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.hades .tp-bullet {
  width: 3px;
  height: 3px;
  cursor: pointer;
  box-sizing: content-box;
  perspective: 400px;
  background: #888;
  border: 5px solid #fff;
  position: absolute;
  transform: translateZ(.01px);
  box-shadow: 0 0 3px 1px #0003;
}

.hades .tp-bullet:hover, .hades .tp-bullet.selected {
  background: #555;
}

.hades .tp-bullet-image {
  width: 120px;
  height: 60px;
  visibility: hidden;
  opacity: 0;
  transform-style: flat;
  perspective: 600px;
  transform-origin: 50% 100%;
  background-position: center;
  background-size: cover;
  transition: all .3s;
  position: absolute;
  top: -80px;
  left: -60px;
  transform: rotateX(-90deg);
  box-shadow: 0 0 3px 1px #0003;
}

.hades .tp-bullet:hover .tp-bullet-image {
  opacity: 1;
  visibility: visible;
  display: block;
  transform: rotateX(0);
}

.hades .tp-thumb {
  opacity: 1;
}

.hades .tp-thumb-img-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #00000040;
  border-radius: 50%;
  margin: 0;
  padding: 3px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.hades .tp-thumb-image {
  box-sizing: border-box;
  border-radius: 50%;
  padding: 3px;
  display: block;
  position: relative;
  box-shadow: inset 5px 5px 10px #00000040;
}

.hades .tp-thumb:hover .tp-thumb-img-wrap, .hades .tp-thumb.selected .tp-thumb-img-wrap {
  background: -moz-linear-gradient(top, #fff 0%, #777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fff), color-stop(100%, #777));
  background: -o-linear-gradient(top, #fff 0%, #777 100%);
  background: -ms-linear-gradient(top, #fff 0%, #777 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#777777", GradientType= 0);
  background: linear-gradient(#fff 0%, #777 100%);
}

.hades .tp-thumb-title:after {
  content: " ";
  width: 0;
  height: 0;
  border: 8px solid #0000;
  border-top-color: #000000bf;
  border-bottom-width: 0;
  margin-left: -8px;
  position: absolute;
  bottom: -8px;
  left: 50%;
}

.hades .tp-tab {
  opacity: 1;
}

.hades .tp-tab-title {
  color: #333;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  display: block;
}

.hades .tp-tab-price {
  text-align: center;
  color: #999;
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  display: block;
}

.hades .tp-tab-button {
  text-align: center;
  color: #fff;
  background: #219bd7;
  border-radius: 4px;
  margin-top: 15px;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}

.hades .tp-tab-inner {
  text-align: center;
}

.hebe.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  z-index: 1000;
  background: #fff;
  display: block;
  position: absolute;
}

.hebe.tparrows:before {
  color: #aaa;
  text-align: center;
  z-index: 2;
  min-width: 70px;
  min-height: 70px;
  background: #fff;
  font-family: revicons;
  font-size: 30px;
  line-height: 70px;
  transition: color .3s;
  display: block;
  position: relative;
}

.hebe.tparrows.tp-leftarrow:before {
  content: "";
}

.hebe.tparrows.tp-rightarrow:before {
  content: "";
}

.hebe.tparrows:hover:before {
  color: #000;
}

.hebe .tp-title-wrap {
  z-index: 0;
  min-height: 60px;
  -webkit-transition: -webkit-transform .3s;
  transform-origin: 0%;
  background: #000000bf;
  margin-left: 0;
  line-height: 60px;
  transition: transform .3s;
  display: inline-block;
  position: absolute;
  top: -10px;
  transform: scaleX(0);
}

.hebe.tp-rightarrow .tp-title-wrap {
  -webkit-transform-origin: 100%;
  right: 0;
}

.hebe.tparrows:hover .tp-title-wrap {
  transform: scaleX(1);
}

.hebe .tp-arr-titleholder {
  text-transform: uppercase;
  color: #fff;
  white-space: nowrap;
  padding: 0 20px 0 90px;
  font-size: 12px;
  font-weight: 600;
  line-height: 90px;
  position: relative;
}

.hebe.tp-rightarrow .tp-arr-titleholder {
  margin-left: 0;
  padding: 0 90px 0 20px;
}

.hebe.tparrows:hover .tp-arr-titleholder {
  opacity: 1;
  transition-delay: .1s;
  transform: translateX(0);
}

.hebe .tp-arr-imgholder {
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 100%;
  right: -90px;
}

.hebe.tp-rightarrow .tp-arr-imgholder {
  left: -90px;
  right: auto;
}

.hebe.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.hebe .tp-bullet {
  width: 3px;
  height: 3px;
  cursor: pointer;
  box-sizing: content-box;
  perspective: 400px;
  background: #fff;
  border: 5px solid #222;
  border-radius: 50%;
  transition: all .3s;
  position: absolute;
  transform: translateZ(.01px);
}

.hebe .tp-bullet:hover, .hebe .tp-bullet.selected {
  background: #222;
  border-color: #fff;
}

.hebe .tp-bullet-image {
  width: 70px;
  height: 70px;
  visibility: hidden;
  opacity: 0;
  transform-style: flat;
  perspective: 600px;
  transform-origin: 50% 100%;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  transition: all .3s;
  position: absolute;
  top: -90px;
  left: -40px;
  transform: scale(0);
}

.hebe .tp-bullet:hover .tp-bullet-image {
  opacity: 1;
  visibility: visible;
  display: block;
  transform: scale(1);
}

.hebe .tp-tab-title {
  color: #a8d8ee;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  font-family: "Roboto Slab" margin-bottom:5px;
}

.hebe .tp-tab-desc {
  color: #fff;
  font-family: Roboto Slab;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.hephaistos.tparrows {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 1000;
  background: #00000080;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.hephaistos.tparrows:hover {
  background: #000;
}

.hephaistos.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 18px;
  line-height: 40px;
  display: block;
}

.hephaistos.tparrows.tp-leftarrow:before {
  content: "";
  margin-left: -2px;
}

.hephaistos.tparrows.tp-rightarrow:before {
  content: "";
  margin-right: -2px;
}

.hephaistos.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.hephaistos .tp-bullet {
  width: 12px;
  height: 12px;
  cursor: pointer;
  box-sizing: content-box;
  background: #999;
  border: 3px solid #f5f5f5;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0 2px 1px #8282824d;
}

.hephaistos .tp-bullet:hover, .hephaistos .tp-bullet.selected {
  background: #fff;
  border-color: #000;
}

.hermes.tparrows {
  cursor: pointer;
  width: 30px;
  height: 110px;
  z-index: 1000;
  background: #00000080;
  display: block;
  position: absolute;
}

.hermes.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 15px;
  line-height: 110px;
  transition: all .3s;
  display: block;
  transform: translateX(0);
}

.hermes.tparrows.tp-leftarrow:before {
  content: "";
}

.hermes.tparrows.tp-rightarrow:before {
  content: "";
}

.hermes.tparrows.tp-leftarrow:hover:before {
  opacity: 0;
  transform: translateX(-20px);
}

.hermes.tparrows.tp-rightarrow:hover:before {
  opacity: 0;
  transform: translateX(20px);
}

.hermes .tp-arr-allwrapper {
  width: 180px;
  height: 140px;
  visibility: hidden;
  -webkit-transition: -webkit-transform .3s .3s;
  perspective: 1000px;
  transition: transform .3s .3s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
  left: auto;
  right: 0;
}

.hermes.tparrows:hover .tp-arr-allwrapper {
  visibility: visible;
}

.hermes .tp-arr-imgholder {
  width: 180px;
  height: 110px;
  transition: all .3s .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-180px);
}

.hermes.tp-rightarrow .tp-arr-imgholder {
  transform: translateX(180px);
}

.hermes.tparrows:hover .tp-arr-imgholder {
  transform: translateX(0);
}

.hermes .tp-arr-titleholder {
  width: 180px;
  text-align: left;
  color: #fff;
  white-space: nowrap;
  letter-spacing: 1px;
  transform-origin: 50% 0;
  box-sizing: border-box;
  background: #000000bf;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 110px;
  transform: rotateX(-90deg);
}

.hermes.tparrows:hover .tp-arr-titleholder {
  transition-delay: .6s;
  transform: rotateX(0);
}

.hermes .tp-bullet {
  width: 16px;
  height: 16px;
  background-color: #0000;
  border-radius: 50%;
  transition: background .3s;
  position: absolute;
  overflow: hidden;
  box-shadow: inset 0 0 0 2px #fff;
}

.hermes .tp-bullet:hover {
  background-color: #0003;
}

.hermes .tp-bullet:after {
  content: " ";
  height: 0;
  width: 100%;
  background-color: #fff;
  transition: height .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 1px #fff;
}

.hermes .tp-bullet.selected:after {
  height: 100%;
}

.hermes .tp-tab {
  opacity: 1;
  box-sizing: border-box;
  padding-right: 10px;
}

.hermes .tp-tab-image {
  width: 100%;
  height: 60%;
  position: relative;
}

.hermes .tp-tab-content {
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  min-height: 40%;
  background: #363636;
  padding: 20px 20px 20px 30px;
  display: block;
  position: absolute;
  bottom: 0;
  left: -10px;
}

.hermes .tp-tab-date {
  color: #888;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.hermes .tp-tab-title {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  display: block;
}

.hermes .tp-tab.selected .tp-tab-title:after {
  width: 0;
  height: 0;
  content: " ";
  border: 30px solid #0000;
  border-left: 10px solid #363636;
  border-right-width: 0;
  margin-bottom: -30px;
  position: absolute;
  bottom: 50%;
  right: -9px;
}

.hermes .tp-tab-mask {
  padding-right: 10px !important;
}

@media only screen and (max-width: 960px) {
  .hermes .tp-tab .tp-tab-title {
    font-size: 14px;
    line-height: 16px;
  }

  .hermes .tp-tab-date {
    margin-bottom: 10px;
    font-size: 11px;
    line-height: 13px;
  }

  .hermes .tp-tab-content {
    padding: 15px 15px 15px 25px;
  }
}

@media only screen and (max-width: 768px) {
  .hermes .tp-tab .tp-tab-title {
    font-size: 12px;
    line-height: 14px;
  }

  .hermes .tp-tab-date {
    margin-bottom: 5px;
    font-size: 10px;
    line-height: 12px;
  }

  .hermes .tp-tab-content {
    padding: 10px 10px 10px 20px;
  }
}

.hesperiden.tparrows {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 1000;
  background: #00000080;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.hesperiden.tparrows:hover {
  background: #000;
}

.hesperiden.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 20px;
  line-height: 40px;
  display: block;
}

.hesperiden.tparrows.tp-leftarrow:before {
  content: "";
  margin-left: -3px;
}

.hesperiden.tparrows.tp-rightarrow:before {
  content: "";
  margin-right: -3px;
}

.hesperiden.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  background: none;
  border-radius: 8px;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.hesperiden .tp-bullet {
  width: 12px;
  height: 12px;
  background: -o-linear-gradient(top, #999 0%, #e1e1e1 100%);
  background: -ms-linear-gradient(top, #999 0%, #e1e1e1 100%);
  filter: progid:dximagetransform. microsoft. gradient(startcolorstr= "#999999", endcolorstr= "#e1e1e1", gradienttype= 0);
  cursor: pointer;
  box-sizing: content-box;
  background: linear-gradient(#999 0%, #e1e1e1 100%);
  border: 3px solid #e5e5e5;
  border-radius: 50%;
  position: absolute;
}

.hesperiden .tp-bullet:hover, .hesperiden .tp-bullet.selected {
  background: #666;
}

.hesperiden .tp-thumb {
  opacity: 1;
  perspective: 600px;
}

.hesperiden .tp-thumb .tp-thumb-title {
  color: #fff;
  z-index: 10000;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  transform-origin: 50% 100%;
  opacity: 0;
  background-color: #000;
  margin-top: -10px;
  padding: 5px 10px;
  font-size: 12px;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotateX(90deg)translateZ(.001px);
}

.hesperiden .tp-thumb:hover .tp-thumb-title {
  opacity: 1;
  transform: rotateX(0);
}

.hesperiden .tp-tab {
  opacity: 1;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  font-family: Roboto, sans-serif;
}

.hesperiden .tp-tab-image {
  width: 60px;
  height: 60px;
  max-height: 100%;
  max-width: 100%;
  float: left;
  display: inline-block;
  position: relative;
}

.hesperiden .tp-tab-content {
  box-sizing: border-box;
  color: #333;
  width: 100%;
  height: 100%;
  background: none;
  margin-top: -15px;
  padding: 15px 15px 15px 85px;
  display: inline-block;
  position: absolute;
  left: 0;
  overflow: hidden;
}

.hesperiden .tp-tab-date {
  color: #aaa;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
  display: block;
}

.hesperiden .tp-tab-title {
  text-align: left;
  color: #333;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: block;
}

.hesperiden .tp-tab:hover, .hesperiden .tp-tab.selected {
  background: #eee;
}

.metis.tparrows {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  background: #fff;
  padding: 10px;
  transition: all .3s;
}

.metis.tparrows:hover {
  background: #ffffffbf;
}

.metis.tparrows:before {
  color: #000;
  transition: all .3s;
}

.metis.tparrows:hover:before {
  transform: scale(1.5);
}

.metis .tp-bullet {
  opacity: 1;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: #00000040;
  border-radius: 50%;
  margin: 0;
  padding: 3px;
  transition: all .3s;
}

.metis .tp-bullet-image {
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  display: block;
  position: relative;
  box-shadow: inset 5px 5px 10px #00000040;
}

.metis .tp-bullet-title {
  color: #fff;
  opacity: 0;
  transform-origin: 50% 100%;
  opacity: 0;
  white-space: nowrap;
  background: #000000bf;
  border-radius: 4px;
  padding: 10px 30px;
  transition: all .3s;
  display: inline-block;
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translateZ(.001px)translateX(-50%)translateY(14px);
}

.metis .tp-bullet:hover .tp-bullet-title {
  opacity: 1;
  transform: rotateX(0)translateX(-50%);
}

.metis .tp-bullet.selected, .metis .tp-bullet:hover {
  background: -moz-linear-gradient(top, #fff 0%, #777 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #fff), color-stop(100%, #777));
  background: -o-linear-gradient(top, #fff 0%, #777 100%);
  background: -ms-linear-gradient(top, #fff 0%, #777 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#777777", GradientType= 0);
  background: linear-gradient(#fff 0%, #777 100%);
}

.metis .tp-bullet-title:after {
  content: " ";
  width: 0;
  height: 0;
  border: 8px solid #0000;
  border-top-color: #000000bf;
  border-bottom-width: 0;
  margin-left: -8px;
  position: absolute;
  bottom: -8px;
  left: 50%;
}

.metis .tp-tab-number {
  color: #fff;
  width: 50px;
  float: left;
  margin-right: 17px;
  font-family: Playfair Display;
  font-size: 40px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

.metis .tp-tab-mask {
  padding-left: 20px;
  transition: padding-left .4s, left .4s, max-width .4s;
  left: 0;
  max-width: 90px !important;
}

.metis:hover .tp-tab-mask {
  padding-left: 0;
  left: 50px;
  max-width: 500px !important;
}

.metis .tp-tab-divider {
  height: 30px;
  width: 1px;
  float: left;
  border-right: 1px solid #0000;
  margin-top: 5px;
  display: inline-block;
}

.metis .tp-tab-title {
  color: #fff;
  padding-top: 10px;
  padding-left: 30px;
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  transition: all .4s;
  display: inline-block;
  position: relative;
  transform: translateX(-100%);
}

.metis .tp-tab-title-mask {
  position: absolute;
  left: 67px;
  overflow: hidden;
}

.metis:hover .tp-tab-title {
  transform: translateX(0);
}

.metis .tp-tab {
  opacity: .15;
  transition: all .4s;
}

.metis .tp-tab:hover, .metis .tp-tab.selected {
  opacity: 1;
}

.metis .tp-tab.selected .tp-tab-divider {
  border-right: 1px solid #cdb083;
}

.metis.tp-tabs {
  padding-left: 50px;
  max-width: 118px !important;
}

.metis.tp-tabs:before {
  content: " ";
  height: 100%;
  width: 88px;
  background: #00000026;
  border-right: 1px solid #ffffff1a;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

.metis.tp-tabs:hover:before {
  width: 118px;
}

@media (max-width: 499px) {
  .metis.tp-tabs:before {
    background: #000000bf;
  }
}

.persephone.tparrows {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 100;
  background: #c8c8c880;
  border: 1px solid #f5f5f5;
  display: block;
  position: absolute;
}

.persephone.tparrows:hover {
  background: #333;
}

.persephone.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 15px;
  line-height: 40px;
  display: block;
}

.persephone.tparrows.tp-leftarrow:before {
  content: "";
}

.persephone.tparrows.tp-rightarrow:before {
  content: "";
}

.persephone.tp-bullets:before {
  content: " ";
  width: 100%;
  height: 100%;
  background: #transparent;
  box-sizing: content-box;
  margin-top: -10px;
  margin-left: -10px;
  padding: 10px;
  position: absolute;
}

.persephone .tp-bullet {
  width: 12px;
  height: 12px;
  cursor: pointer;
  box-sizing: content-box;
  background: #aaa;
  border: 1px solid #e5e5e5;
  position: absolute;
}

.persephone .tp-bullet:hover, .persephone .tp-bullet.selected {
  background: #222;
}

.uranus.tparrows {
  width: 50px;
  height: 50px;
  background: none;
}

.uranus.tparrows:before {
  width: 50px;
  height: 50px;
  font-size: 40px;
  line-height: 50px;
  transition: all .3s;
}

.uranus.tparrows:hover:before {
  opacity: .75;
}

.uranus .tp-bullet {
  background: none;
  border-radius: 50%;
  transition: box-shadow .3s;
  box-shadow: 0 0 0 2px #fff0;
}

.uranus .tp-bullet.selected, .uranus .tp-bullet:hover {
  background: none;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
}

.uranus .tp-bullet-inner {
  -webkit-transition: background-color .3s, -webkit-transform .3s;
  width: 100%;
  height: 100%;
  text-indent: -999em;
  cursor: pointer;
  background-color: #ffffff4d;
  border-radius: 50%;
  outline: none;
  transition: background-color .3s, transform .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.uranus .tp-bullet.selected .tp-bullet-inner, .uranus .tp-bullet:hover .tp-bullet-inner {
  background-color: #fff;
  transform: scale(.4);
}

.zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  z-index: 100;
  background: #0000001a;
  border-radius: 35px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.zeus.tparrows:before {
  color: #fff;
  text-align: center;
  z-index: 2;
  font-family: revicons;
  font-size: 20px;
  line-height: 70px;
  display: block;
  position: relative;
}

.zeus.tparrows.tp-leftarrow:before {
  content: "";
}

.zeus.tparrows.tp-rightarrow:before {
  content: "";
}

.zeus .tp-title-wrap {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #00000080;
  border-radius: 50%;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translateX(100%);
}

.zeus.tparrows:hover .tp-arr-imgholder {
  opacity: 1;
  transform: translateX(0);
}

.zeus.tparrows:hover .tp-title-wrap {
  opacity: 1;
  transform: scale(1);
}

.zeus .tp-bullet {
  box-sizing: content-box;
  width: 13px;
  height: 13px;
  background-color: #0000;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: opacity .3s;
}

.zeus .tp-bullet:after {
  content: "";
  width: 100%;
  height: 100%;
  transform-origin: 50%;
  -webkit-transition: -webkit-transform .3s;
  background-color: #fff;
  border-radius: 50%;
  transition: transform .3s;
  position: absolute;
  left: 0;
  transform: scale(0);
}

.zeus .tp-bullet:hover:after, .zeus .tp-bullet.selected:after {
  transform: scale(1.2);
}

.zeus .tp-bullet-image, .zeus .tp-bullet-imageoverlay {
  width: 135px;
  height: 60px;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transform-origin: 50%;
  background: #00000080 center / cover;
  border-radius: 4px;
  margin-left: -65px;
  transition: all .3s;
  position: absolute;
  bottom: 25px;
  left: 50%;
}

.zeus .tp-bullet-title, .zeus .tp-bullet-imageoverlay {
  z-index: 2;
  transition: all .5s;
}

.zeus .tp-bullet-title {
  color: #fff;
  text-align: center;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  backface-visibility: hidden;
  transform-origin: 50%;
  width: 135px;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  transition: all .3s;
  position: absolute;
  bottom: 45px;
  left: -57px;
}

.zeus .tp-bullet:hover .tp-bullet-title, .zeus .tp-bullet:hover .tp-bullet-image, .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.zeus .tp-thumb {
  opacity: 1;
}

.zeus .tp-thumb-over {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #00000040;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.zeus .tp-thumb-more:before {
  color: #aaa;
  color: #ffffffbf;
  text-align: left;
  z-index: 2;
  z-index: 2;
  content: "";
  font-family: revicons;
  font-size: 12px;
  line-height: 12px;
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
}

.zeus .tp-thumb-title {
  letter-spacing: 1px;
  color: #fff;
  text-align: left;
  z-index: 2;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 35px 20px 20px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.zeus .tp-thumb.selected .tp-thumb-more:before, .zeus .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa;
}

.zeus .tp-thumb.selected .tp-thumb-over, .zeus .tp-thumb:hover .tp-thumb-over {
  background: #000;
}

.zeus .tp-thumb.selected .tp-thumb-title, .zeus .tp-thumb:hover .tp-thumb-title {
  color: #fff;
}

.zeus .tp-tab {
  opacity: 1;
  box-sizing: border-box;
}

.zeus .tp-tab-title {
  text-align: center;
  color: #fff;
  background: #00000040;
  padding: 9px 10px;
  font-family: Roboto Slab, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 13px;
  display: block;
}

.zeus .tp-tab:hover .tp-tab-title, .zeus .tp-tab.selected .tp-tab-title {
  color: #000;
  background: #fff;
}

.post-tabs .tp-thumb {
  opacity: 1;
}

.post-tabs .tp-thumb-over {
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #252525;
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.post-tabs .tp-thumb-more:before {
  color: #aaa;
  color: #ffffffbf;
  text-align: left;
  z-index: 2;
  z-index: 2;
  content: "";
  font-family: revicons;
  font-size: 12px;
  line-height: 12px;
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
}

.post-tabs .tp-thumb-title {
  letter-spacing: 1px;
  color: #fff;
  text-align: left;
  z-index: 2;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 15px 30px 15px 15px;
  font-family: raleway;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.post-tabs .tp-thumb.selected .tp-thumb-more:before, .post-tabs .tp-thumb:hover .tp-thumb-more:before {
  color: #aaa;
}

.post-tabs .tp-thumb.selected .tp-thumb-over, .post-tabs .tp-thumb:hover .tp-thumb-over {
  background: #fff;
}

.post-tabs .tp-thumb.selected .tp-thumb-title, .post-tabs .tp-thumb:hover .tp-thumb-title {
  color: #000;
}

/*# sourceMappingURL=index.db5859e1.css.map */
